.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {

}

body {
    background-color: rgba($color: #000000, $alpha: .01);
    padding: 50px 0 0 0;
    .background {
        background-image: url('./low-poly-grid-haikei.svg');
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        pointer-events: none;

        &:after {
            content: "";
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            display: block;
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
        }
    }
}

a, a:visited, a:active {
    text-decoration: none;
    color: black;
}

a {
    transition: all ease-in-out 150ms;
}

a:hover {
    color: rgba($color: #000000, $alpha: .6);
}

.header{
    z-index: 10000;
    width: 100%;
    position: fixed;
    top: 0;
    display: flex;
	flex-direction: row;
	justify-content: space-around;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: white;
    nav {
        display: flex;
        align-content: center;
        align-items: center;
    }
    ul {
        padding: 0;
        list-style-type: none;
        margin: auto 0;
        display: inline-block;
    }
    li{
        display: inline-block;
        max-width: 33%;
        a, a:visited, a:active{
            padding: 20px 15px;
            text-decoration: none;
            color: black;
            width: 100%;
            height: 100%;
            display: inline-block;
        }
        a:active {
            color: grey;
        }
    }
}

main {
    max-width: 800px;
    margin: 0 auto;
}

h1, h2 {
    text-align: center;
}

h2 {
    font-size: 3em;
    font-weight: 400;
}

section {
    min-height: 100vh;
    padding-top: 60px;
}

#about {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 2.5em;
    padding: 0 50px;

    h1 {
        margin-top: -50px;
        margin-bottom: 160px;
        font-size: 6em;
        font-weight: 400;
    }
}

#portfolio {
    .project {
        min-height: 200px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        border-radius: 5px;
        background-color: white;
        box-sizing: border-box;
        max-width: 90vw;
        margin: 0 auto;
        margin-bottom: 100px;
        .project-image {
            max-width: 100%;
            height: auto;
        }

        h3 {
            text-align: center;
            width: 100%;
            font-size: 2em;
            font-weight: 500;
            margin-bottom: 50px;
            a {
                text-decoration: underline;
            }
        }

        .description {
            padding: 0 30px 70px 30px;
        }

        .links {
            display: flex;
            justify-content: center;
            margin-top: -20px;
            margin-bottom: 20px;
            a:has(img) {
                padding: 10px;
            }
            img {
                opacity: .8;
                max-height: 50px;
            }
        }
    }
}

#contact {
    h2 {
        padding-bottom: 30px;
    }

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    line-height: 2.5em;
    padding: 0 50px;

    div {
        text-align: center;
    }
}

footer {
    margin-top: 50px;
    min-height: 50px;
    background-color: white;
    width: 100%;
    display: block;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    display: grid;
    flex-direction: column;

    .copyright{
        text-align: center;
        padding: 10px;
        justify-self: center;
    }

    a {
        font-size: .7em;
        padding: 5px;
        justify-self: center;
    }
}
